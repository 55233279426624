export default {
  betTickerList: [],
  betTickerListLoading: false,
  betTickerListSearch: '',
  activeSidebarOption: null,
  selectedSearchField: 'eventName',
  betTickerConfigurations: [],
  allSportsMarketDisplayConfigurations: {},
  selectedBetTicker: null,
  showDisabledTickers: false,
  onlyFlaggedBets: false,

  liabilityEvents: [],
  liabilityEventsLoading: false,
  liabilityFiltersMeta: {
    sports: [],
    competitions: [],
  },
  liabilityEvent: null,
  liabilityEventLoading: false,
  liabilityEventMarketsDisplayConfiguration: null,
  liabilityMarketsObject: null,
  globalLiabilityMarketsObject: null,
  liabilityMarketSelectionsObject: null,
  globalLiabilityMarketSelectionsObject: null,
  marketGroups: [],
  isGlobalEventLiabilityOperatorSelected: false,

  allCustomers: {
    isInitializing: false,
    isLoading: false,
    initialized: false,
    filters: [],
    page: 1,
    limit: 50,
    columns: [],
    totalCount: 0,
    data: [],
    sidebar: '',
  },
  customer: {
    isInitializing: false,
    isLoading: false,
    key: null,
    data: null,
    sidebar: '',
    editNickname: false,
    bets: {
      isLoading: false,
      filters: [],
      page: 1,
      limit: 50,
      columns: [],
      totalCount: 0,
      data: [],
    },
    notes: {
      isInitializing: false,
      isLoading: false,
      orderBy: 'CREATED_AT_DESC',
      first: 5,
      totalCount: 0,
      data: [],
    },
  },
};
