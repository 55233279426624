import {
  cloneDeep,
  findIndex,
  filter,
  slice,
  concat,
  map,
  find,
  isNil,
  each,
  assign,
  trim,
  toUpper,
  split,
  join,
  reduce,
  isEqual,
} from 'lodash';
import { parse, startOfDay, endOfDay } from 'date-fns';
import router from '@/router';
import * as api from '@/services/api';
import socket from '@/services/socket';
import types from './mutation-types';
import { formatBetsData, formatBetData } from '@/components/bet-ticker/betTicker-helper';
import computeStartsAt from '@/utils/computeStartsAt';
import { updateLiability, generateIdentifierByMarketParams } from '@/services/helpers/liability-updater';
import { parseAllFilters, composeAllFilters } from '@/services/helpers/bet-ticker-filters';
import {
  parseLimit,
  parsePage,
  flattenCustomerObject,
  bulkMapCustomerListFiltersToGraphQl,
  bulkMapCustomerDetailsFiltersToGraphQl,
  getCustomerProfilingListColumns,
  getCustomerProfilingDetailsColumns,
} from '@/services/helpers/customer-profiling';
import { getSuperAdminData } from '@/services/helpers/super-admin';

export default {
  loadAllBets({ commit, getters }, loadOlder) {
    const allBets = cloneDeep(getters.betTickerList);
    const offset = loadOlder ? filter(allBets, (bet) => !bet.isBetPart).length : 0;
    const filterOperator = getters.selectedSearchField === 'eventName' ? 'includesInsensitive' : 'equalTo';
    const options = {
      first: 50,
      offset,
      filter: {
        acceptanceTime: {
          isNull: false,
        },
      },
    };
    if (getters.betTickerListSearch) {
      options.filter = {
        ...options.filter,
        betPartsByBetId: {
          every: {
            huddleContentInfoByBetPartId: {
              eventByEventId: {
                [getters.selectedSearchField]: { [filterOperator]: getters.betTickerListSearch },
              },
            },
          },
        },
      };
    }
    if (getters.onlyFlaggedBets) {
      options.filter = {
        ...options.filter,
        isFlagged: {
          equalTo: true,
        },
      };
    }
    commit(types.SET_BET_TICKER_LIST_LOADING, true);
    api.useAllBetsQuery(options)
      .then((res) => {
        let bets = res.data.allBets.nodes ?? [];
        bets = formatBetsData(bets);
        if (loadOlder) bets = concat(allBets, bets);

        commit(types.UPDATE_BET_TICKER_LIST, bets);
      })
      .catch(() => {
        commit(types.UPDATE_BET_TICKER_LIST, []);
      })
      .finally(() => {
        commit(types.SET_BET_TICKER_LIST_LOADING, false);
      });
  },
  loadBetTickerBets({ commit, getters }, { betTickerId, loadOlder }) {
    const allBets = cloneDeep(getters.betTickerList);
    const offset = loadOlder ? filter(allBets, (bet) => !bet.isBetPart).length : 0;
    const options = {
      bettickerid: betTickerId,
      first: 50,
      offset,
    };
    commit(types.SET_BET_TICKER_LIST_LOADING, true);
    if (getters.onlyFlaggedBets) {
      options.filter = {
        ...options.filter,
        isFlagged: {
          equalTo: true,
        },
      };
    }
    api.useAllDynamicBetsQuery(options)
      .then((res) => {
        let bets = res.data.allBetsDynamic.nodes ?? [];
        bets = formatBetsData(bets);
        if (loadOlder) bets = concat(allBets, bets);

        commit(types.UPDATE_BET_TICKER_LIST, bets);
      })
      .catch(() => {
        commit(types.UPDATE_BET_TICKER_LIST, []);
      })
      .finally(() => {
        commit(types.SET_BET_TICKER_LIST_LOADING, false);
      });
  },
  toggleParlayBet({ getters, commit }, betId) {
    let betList = cloneDeep(getters.betTickerList);
    const singleBetIndex = findIndex(betList, (bet) => bet.betId === betId);
    const singleBet = betList[singleBetIndex];
    singleBet.toggled = !singleBet.toggled;
    if (singleBet.toggled) {
      betList = [
        ...slice(betList, 0, singleBetIndex + 1),
        ...singleBet.betParts,
        ...slice(betList, singleBetIndex + 1),
      ];
    } else {
      betList = filter(betList, (bet, index) => (index < singleBetIndex + 1 || index > singleBetIndex + singleBet.betParts.length));
    }
    commit(types.UPDATE_BET_TICKER_LIST, betList);
  },
  setActiveSidebarOption({ commit }, sidebarOption) {
    commit(types.SET_ACTIVE_SIDEBAR_OPTION, sidebarOption);
  },
  setSelectedSearchField({ commit }, field) {
    commit(types.SET_SELECTED_SEARCH_FIELD, field);
  },
  updateBetTickerSearchValue({ commit }, value) {
    commit(types.UPDATE_BET_TICKER_SEARCH, value);
  },
  resetBets({ commit }) {
    commit(types.SET_BET_TICKER_LIST_LOADING, true);
    commit(types.UPDATE_BET_TICKER_LIST, []);
  },
  async loadBetTickerConfigurations({ commit, dispatch }) {
    const allBetTickersResponse = await api.useAllBetTickersQuery();
    const allBetTickers = allBetTickersResponse?.data?.allBetTickerConfigurations?.nodes;
    commit(types.SET_BET_TICKER_CONFIGURATIONS, allBetTickers);
    const marketDisplayConfigurationsResponse = await api.loadAllSportsMarketDisplayConfigurations();
    const marketDisplayConfigurations = marketDisplayConfigurationsResponse?.data?.allMarketDisplayConfigurations?.nodes || [];
    const marketsDisplayConfigurationObj = marketDisplayConfigurations.reduce((markets, market) => ({
      ...markets,
      [`${market.sportId}_${market.marketCode}_${market.selections}`]: market,
    }), {});
    commit(types.SET_ALL_SPORTS_DISPLAY_MARKETS_CONFIGURATION, marketsDisplayConfigurationObj);
    if (!filter(allBetTickers, ({ enabled }) => enabled)?.length) {
      dispatch('loadAllBets');
      socket.subscribeToBetTicker();
      socket.subscribeToBetInfo();
    } else {
      dispatch('selectBetTicker', filter(allBetTickers, ({ enabled }) => enabled)[0]);
    }
  },
  unloadBetTicker({ commit, getters }) {
    if (getters.betTickerList?.length) commit(types.UPDATE_BET_TICKER_LIST, []);
    const { selectedBetTicker } = getters;
    const betTickerId = selectedBetTicker?.betTickerId;
    socket.unsubscribeFromBetTicker(betTickerId);
    socket.unsubscribeFromBetInfo();
    commit(types.SELECT_BET_TICKER, null);
  },
  async reloadBetTicker({ getters, dispatch }) {
    dispatch('unloadBetTicker');
    if (!getters.selectedBetTicker?.betTickerId) {
      dispatch('loadBetTickerConfigurations');
      return;
    }
    const id = getters.selectedBetTicker.betTickerId;
    await dispatch('loadBetTickerConfigurations');
    dispatch('selectBetTicker', find(getters.betTickerConfigurations, { betTickerId: id }));
  },
  updateBet({ dispatch }, bet) {
    if (router?.currentRoute?.value?.name === 'customer-profiling-details') {
      dispatch('updateCustomerBets', bet);
      return;
    }
    dispatch('updateBetTickerBets', bet);
  },
  updateBetTickerBets({ commit, getters }, bet) {
    const formattedBet = formatBetData(bet);
    const allBets = cloneDeep(getters.betTickerList);
    allBets.unshift(formattedBet);
    commit(types.UPDATE_BET_TICKER_LIST, allBets);
  },
  updateCustomerBets({ commit, getters }, bet) {
    const formattedBet = formatBetData(bet);
    const allBets = cloneDeep(getters.customerBetsData);
    allBets.unshift(formattedBet);
    commit(types.SET_CUSTOMER_BETS_DATA, allBets);
  },
  async selectBetTicker({
    commit, dispatch, getters, rootGetters,
  }, betTicker) {
    const { selectedBetTicker } = getters;
    const betTickerId = selectedBetTicker?.betTickerId;
    socket.unsubscribeFromBetTicker(betTickerId);
    socket.unsubscribeFromBetInfo();
    if (!betTicker) {
      commit(types.SELECT_BET_TICKER, null);
      dispatch('loadAllBets');
      socket.subscribeToBetTicker();
      socket.subscribeToBetInfo();
      return;
    }

    const selectedOddFormat = rootGetters.selectedOddFormat?.name || 'american';
    const parsedActiveFilters = await parseAllFilters(betTicker.filters, { selectedOddFormat });
    const parsedInactiveFilters = await parseAllFilters(map(betTicker.inactiveFilters, (type) => ({ type })), { selectedOddFormat });

    const mappedBetTicker = {
      ...betTicker,
      inactiveFilters: parsedInactiveFilters,
      filters: parsedActiveFilters,
    };
    commit(types.SELECT_BET_TICKER, mappedBetTicker);
    if (!mappedBetTicker.enabled) {
      commit(types.UPDATE_BET_TICKER_LIST, []);
      return;
    }
    dispatch('loadBetTickerBets', { betTickerId: mappedBetTicker.betTickerId });
    socket.subscribeToBetTicker(mappedBetTicker.betTickerId);
    socket.subscribeToBetInfo();
  },
  async createBetTicker({ commit, getters, dispatch }) {
    const createdBetTicker = await api.createBetTicker();
    const betTicker = {
      betTickerId: createdBetTicker.data,
      betTickerName: 'New Ticker',
      filters: [],
      inactiveFilters: [],
      enabled: true,
    };
    const betTickers = cloneDeep(getters.betTickerConfigurations);
    commit(types.SET_BET_TICKER_CONFIGURATIONS, concat(betTickers, [betTicker]));
    dispatch('selectBetTicker', betTicker);
  },
  betTickerAction({ commit, getters, dispatch }, { betTicker, action, name }) {
    if (action === 'rename') {
      api.renameBetTicker({ betTickerId: betTicker.betTickerId, name })
        .then(() => {
          const betTickers = cloneDeep(getters.betTickerConfigurations);
          const renamedBetTicker = find(betTickers, (ticker) => ticker.betTickerId === betTicker.betTickerId);
          if (!renamedBetTicker) return;
          renamedBetTicker.betTickerName = name;
          commit(types.SET_BET_TICKER_CONFIGURATIONS, betTickers);
        })
        .catch((err) => console.log(err));
    }
    if (action === 'duplicate') {
      api.duplicateBetTicker(betTicker.betTickerId)
        .then(() => {
          api.useAllBetTickersQuery()
            .then((response) => {
              const allBetTickers = response?.data?.allBetTickerConfigurations?.nodes;
              commit(types.SET_BET_TICKER_CONFIGURATIONS, allBetTickers);
            })
            .catch((err) => console.log(err));
        })
        .catch((err) => console.log(err));
    }
    if (action === 'delete') {
      api.deleteBetTicker(betTicker.betTickerId)
        .then(() => {
          const betTickers = cloneDeep(getters.betTickerConfigurations);
          const filteredBetTickers = filter(betTickers, (ticker) => ticker.betTickerId !== betTicker.betTickerId);
          commit(types.SET_BET_TICKER_CONFIGURATIONS, filteredBetTickers);
        })
        .catch((err) => console.log(err));
    }
    if (action === 'toggle-ticker') {
      api.updateBetTicker({ ...betTicker, enabled: !betTicker.enabled }).then(() => {
        const { selectedBetTicker } = getters;
        const betTickers = cloneDeep(getters.betTickerConfigurations);
        const updatedBetTickers = map(betTickers, (ticker) => {
          if (ticker.betTickerId === betTicker.betTickerId) {
            return { ...ticker, enabled: !betTicker.enabled };
          }
          return ticker;
        });
        commit(types.SET_BET_TICKER_CONFIGURATIONS, updatedBetTickers);

        // If the selected ticker is the one being toggled, update the selected ticker
        if (selectedBetTicker?.betTickerId === betTicker.betTickerId) {
          const { showDisabledTickers, betTickerConfigurations } = getters;
          // If show disabled tickers is enabled, we can keep the selected ticker
          if (showDisabledTickers) {
            const selectedBetTickerConfiguration = find(betTickerConfigurations, (ticker) => ticker.betTickerId === selectedBetTicker?.betTickerId);
            dispatch('selectBetTicker', { ...selectedBetTickerConfiguration, enabled: !betTicker.enabled });
          } else {
          // If show disabled tickers is disabled, select the first enabled ticker if available
            dispatch('selectBetTicker', find(betTickerConfigurations, { enabled: true }) || null);
          }
        }
      });
    }
  },
  addBetTickerHighlight({ getters, commit, dispatch }, payload) {
    const { betTickerId = '' } = getters.selectedBetTicker;
    const betTicker = find(getters.betTickerConfigurations, { betTickerId });
    if (!betTicker) return;

    const currentHighlights = betTicker.highlights || [];
    const newHighlight = { ...payload.highlight, index: currentHighlights.length };
    const updatedHighlights = [...currentHighlights, newHighlight];
    let updatedTicker = { ...betTicker, highlights: updatedHighlights };
    const oldTickers = cloneDeep(getters.betTickerConfigurations);
    let updatedTickers = map(getters.betTickerConfigurations, (ticker) => {
      if (betTickerId !== ticker.betTickerId) return ticker;
      return updatedTicker;
    });
    commit(types.SET_BET_TICKER_CONFIGURATIONS, updatedTickers);
    dispatch('selectBetTicker', updatedTicker);

    api.updateBetTickerHighlights(betTickerId, updatedHighlights)
      .then((response) => {
        updatedTicker = { ...betTicker, highlights: response.data };
        updatedTickers = map(getters.betTickerConfigurations, (ticker) => {
          if (betTickerId !== ticker.betTickerId) return ticker;
          return updatedTicker;
        });
        commit(types.SET_BET_TICKER_CONFIGURATIONS, updatedTickers);
        dispatch('selectBetTicker', updatedTicker);
      })
      .catch((error) => {
        console.error(error);
        commit(types.SET_BET_TICKER_CONFIGURATIONS, oldTickers);
        dispatch('selectBetTicker', betTicker);
      });
  },
  replaceBetTickerHighlight({ getters, commit, dispatch }, payload) {
    const { betTickerId = '' } = getters.selectedBetTicker;
    const betTicker = find(getters.betTickerConfigurations, { betTickerId });
    if (!betTicker) return;

    const updatedHighlights = map(betTicker.highlights || [], (highlight, index) => {
      if (payload.index !== index) return highlight;
      return payload.highlight;
    });
    let updatedTicker = { ...betTicker, highlights: updatedHighlights };
    const oldTickers = cloneDeep(getters.betTickerConfigurations);
    let updatedTickers = map(getters.betTickerConfigurations, (ticker) => {
      if (betTickerId !== ticker.betTickerId) return ticker;
      return updatedTicker;
    });
    commit(types.SET_BET_TICKER_CONFIGURATIONS, updatedTickers);
    dispatch('selectBetTicker', updatedTicker);

    api.updateBetTickerHighlights(betTickerId, updatedHighlights)
      .then((response) => {
        updatedTicker = { ...betTicker, highlights: response.data };
        updatedTickers = map(getters.betTickerConfigurations, (ticker) => {
          if (betTickerId !== ticker.betTickerId) return ticker;
          return updatedTicker;
        });
        commit(types.SET_BET_TICKER_CONFIGURATIONS, updatedTickers);
        dispatch('selectBetTicker', updatedTicker);
      })
      .catch((error) => {
        console.error(error);
        commit(types.SET_BET_TICKER_CONFIGURATIONS, oldTickers);
        dispatch('selectBetTicker', betTicker);
      });
  },
  removeBetTickerHighlight({ getters, commit, dispatch }, highlightIndex) {
    const { betTickerId = '' } = getters.selectedBetTicker;
    const betTicker = find(getters.betTickerConfigurations, { betTickerId });
    if (!betTicker) return;

    const updatedHighlights = cloneDeep(betTicker.highlights);
    updatedHighlights.splice(highlightIndex, 1);
    let updatedTicker = { ...betTicker, highlights: updatedHighlights };
    const oldTickers = cloneDeep(getters.betTickerConfigurations);
    let updatedTickers = map(getters.betTickerConfigurations, (ticker) => {
      if (betTickerId !== ticker.betTickerId) return ticker;
      return updatedTicker;
    });
    commit(types.SET_BET_TICKER_CONFIGURATIONS, updatedTickers);
    dispatch('selectBetTicker', updatedTicker);

    api.updateBetTickerHighlights(betTickerId, updatedHighlights)
      .then((response) => {
        updatedTicker = { ...betTicker, highlights: response.data };
        updatedTickers = map(getters.betTickerConfigurations, (ticker) => {
          if (betTickerId !== ticker.betTickerId) return ticker;
          return updatedTicker;
        });
        commit(types.SET_BET_TICKER_CONFIGURATIONS, updatedTickers);
        dispatch('selectBetTicker', updatedTicker);
      })
      .catch((error) => {
        console.error(error);
        commit(types.SET_BET_TICKER_CONFIGURATIONS, oldTickers);
        dispatch('selectBetTicker', betTicker);
      });
  },
  setBetTickerHighlights({ getters, commit, dispatch }, highlights) {
    const { betTickerId = '' } = getters.selectedBetTicker;
    const betTicker = find(getters.betTickerConfigurations, { betTickerId });
    if (!betTicker) return;

    let updatedTicker = { ...betTicker, highlights };
    let oldTickers = cloneDeep(getters.betTickerConfigurations);
    let updatedTickers = map(getters.betTickerConfigurations, (ticker) => {
      if (betTickerId !== ticker.betTickerId) return ticker;
      return updatedTicker;
    });
    commit(types.SET_BET_TICKER_CONFIGURATIONS, updatedTickers);
    dispatch('selectBetTicker', updatedTicker);

    api.updateBetTickerHighlights(betTickerId, highlights)
      .then((response) => {
        updatedTicker = { ...betTicker, highlights: response.data };
        oldTickers = cloneDeep(getters.betTickerConfigurations);
        updatedTickers = map(getters.betTickerConfigurations, (ticker) => {
          if (betTickerId !== ticker.betTickerId) return ticker;
          return updatedTicker;
        });
        commit(types.SET_BET_TICKER_CONFIGURATIONS, updatedTickers);
        dispatch('selectBetTicker', updatedTicker);
      })
      .catch((error) => {
        console.error(error);
        commit(types.SET_BET_TICKER_CONFIGURATIONS, oldTickers);
        dispatch('selectBetTicker', betTicker);
      });
  },
  toggleFilters({ getters, commit }, filterItem) {
    const selectedBetTicker = cloneDeep(getters.selectedBetTicker);
    if (find(selectedBetTicker.inactiveFilters, { id: filterItem.id })) {
      selectedBetTicker.inactiveFilters = filter(selectedBetTicker.inactiveFilters, (filterObj) => filterObj.id !== filterItem.id);
      commit(types.SELECT_BET_TICKER, selectedBetTicker);
      return;
    }
    if (find(selectedBetTicker.filters, { id: filterItem.id })) {
      selectedBetTicker.filters = filter(selectedBetTicker.filters, (filterObj) => filterObj.id !== filterItem.id);
      commit(types.SELECT_BET_TICKER, selectedBetTicker);
      return;
    }
    selectedBetTicker.inactiveFilters.push(filterItem);
    commit(types.SELECT_BET_TICKER, selectedBetTicker);
  },
  updateFilters({ getters, commit }, filterItem) {
    const selectedBetTicker = cloneDeep(getters.selectedBetTicker);
    if (find(selectedBetTicker.inactiveFilters, { id: filterItem.id })) {
      selectedBetTicker.inactiveFilters = map(selectedBetTicker.inactiveFilters, (inactiveFilter) => {
        if (inactiveFilter.id === filterItem.id) {
          return filterItem;
        }
        return inactiveFilter;
      });

      if (filterItem.conditionType && !isNil(filterItem.parameter)) {
        selectedBetTicker.inactiveFilters = filter(selectedBetTicker.inactiveFilters, (filterObj) => filterObj.id !== filterItem.id);
        selectedBetTicker.filters.push(filterItem);
        commit(types.SELECT_BET_TICKER, selectedBetTicker);
        return;
      }
      commit(types.SELECT_BET_TICKER, selectedBetTicker);
      return;
    }
    if (find(selectedBetTicker.filters, { id: filterItem.id })) {
      selectedBetTicker.filters = map(selectedBetTicker.filters, (activeFilter) => {
        if (activeFilter.id === filterItem.id) {
          return filterItem;
        }
        return activeFilter;
      });

      if (!filterItem.conditionType || isNil(filterItem.parameter) || filterItem.parameter === '') {
        selectedBetTicker.filters = filter(selectedBetTicker.filters, (filterObj) => filterObj.id !== filterItem.id);
        selectedBetTicker.inactiveFilters.push(filterItem);
        commit(types.SELECT_BET_TICKER, selectedBetTicker);
        return;
      }
      commit(types.SELECT_BET_TICKER, selectedBetTicker);
      return;
    }
    selectedBetTicker.filters = map(selectedBetTicker.filters, (activeFilter) => {
      if (activeFilter.id === filterItem.id) {
        return filterItem;
      }
      return activeFilter;
    });
    commit(types.SELECT_BET_TICKER, selectedBetTicker);
  },
  saveFilters({ getters, commit, dispatch }, selectedOddFormat) {
    const selectedBetTicker = cloneDeep(getters.selectedBetTicker);
    const composedActiveFilters = composeAllFilters(selectedBetTicker.filters, { selectedOddFormat });
    const composedInactiveFilters = composeAllFilters(selectedBetTicker.inactiveFilters, { selectedOddFormat });
    const betTicker = {
      betTickerId: selectedBetTicker.betTickerId,
      enabled: selectedBetTicker.enabled,
      inactiveFilters: map(composedInactiveFilters, (filterItem) => filterItem.type),
      filters: composedActiveFilters,
    };
    api.updateBetTicker(betTicker)
      .then(() => {
        api.useAllBetTickersQuery()
          .then((response) => {
            const allBetTickers = response?.data?.allBetTickerConfigurations?.nodes;
            commit(types.SET_BET_TICKER_CONFIGURATIONS, allBetTickers);
            const findSelectedBetTicker = find(allBetTickers, (ticker) => ticker.betTickerId === betTicker.betTickerId);
            dispatch('selectBetTicker', findSelectedBetTicker);
            dispatch('setActiveSidebarOption', false);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  },
  closeFilters({ getters, dispatch }, betTickerId) {
    const betTickers = getters.betTickerConfigurations;
    const findSelectedBetTicker = find(betTickers, (ticker) => ticker.betTickerId === betTickerId);
    dispatch('selectBetTicker', findSelectedBetTicker);
    dispatch('setActiveSidebarOption', false);
  },
  toggleShowDisabledTickers({ getters, commit, dispatch }) {
    const showDisabledTickers = !getters.showDisabledTickers;
    const { selectedBetTicker } = getters;
    // In case the selected ticker is disabled, we need to select the first enabled ticker if available
    if (selectedBetTicker && !selectedBetTicker.enabled) {
      const { betTickerConfigurations } = getters;
      dispatch('selectBetTicker', find(betTickerConfigurations, { enabled: true }) || null);
    }
    commit(types.SHOW_DISABLED_TICKERS, showDisabledTickers);
  },
  toggleFlag(_, { betId, isFlagged }) {
    const action = isFlagged ? api.unflag : api.flag;
    action(betId)
      .catch((error) => {
        console.error(error);
      });
  },
  setIsBetFlagged({ getters, commit }, { betId, isFlagged }) {
    const betList = cloneDeep(getters.betTickerList);
    const singleBet = find(betList, (bet) => bet.betId === betId);
    singleBet.isFlagged = isFlagged;
    commit(types.UPDATE_BET_TICKER_LIST, betList);
  },
  refreshHighlightedBets({ getters, commit }) {
    const betList = cloneDeep(getters.betTickerList);
    each(betList, (bet) => {
      const betObj = bet;
      if (bet.highlightedRow) betObj.highlightedRow = false;
    });
    commit(types.UPDATE_BET_TICKER_LIST, betList);
  },
  loadFiltersSportsAndCompetitions({ getters, commit }) {
    commit(types.SET_FILTERS_LOADING, true);
    api.findSportsAndCompetitions({
      sportId: getters.filtersSelectedSport?.sportId,
      searchValue: trim(getters.filtersSearchValue),
    })
      .then(({ sports, competitions }) => {
        commit(types.SET_FILTERS_SPORTS, sports);
        commit(types.SET_FILTERS_COMPETITIONS, competitions);
      })
      .catch((error) => {
        console.error(error);
        commit(types.SET_FILTERS_SPORTS, []);
        commit(types.SET_FILTERS_COMPETITIONS, []);
      })
      .finally(() => {
        commit(types.SET_FILTERS_LOADING, false);
      });
  },
  setFiltersSearchValue({ commit }, newSearchValue) {
    commit(types.SET_FILTERS_SEARCH_VALUE, newSearchValue);
  },
  setOnlyFlaggedBets({ getters, commit, dispatch }, newOnlyFlaggedBets) {
    commit(types.SET_ONLY_FLAGGED_BETS, newOnlyFlaggedBets);

    if (getters.selectedBetTicker) {
      dispatch('loadBetTickerBets', {
        betTickerId: getters.selectedBetTicker.betTickerId,
        loadOlder: false,
      });
    } else {
      dispatch('loadAllBets', false);
    }
  },
  async loadLiabilityEvents({ getters, commit, rootGetters }, {
    sport, competition, from, to, state, search, page, limit,
  }) {
    const marketCodes = ['RESULT'];
    const lineMarketCodes = ['GOAL_OVER_UNDER', 'POINT_OVER_UNDER', 'RUN_OVER_UNDER', 'GOAL_HANDICAP', 'POINT_HANDICAP', 'RUN_HANDICAP'];
    const startDate = from ? startOfDay(parse(from, 'yyyy-MM-dd', new Date())) : '';
    const endDate = to ? endOfDay(parse(to, 'yyyy-MM-dd', new Date())) : '';
    if (getters.liabilityEvents?.length) {
      const { isSuperAdmin, SUPER_ADMIN_CLIENT, client } = getSuperAdminData();
      const allOperatorsIds = map(rootGetters.operatorsList, (operator) => operator.operatorId);
      const subscribeToGlobal = isSuperAdmin && client === SUPER_ADMIN_CLIENT;
      socket.unsubscribeFromEventListLiabilities({
        eventIds: map(getters.liabilityEvents, (e) => e.eventId),
        subscribeToGlobal,
        operatorIds: subscribeToGlobal ? allOperatorsIds : [client],
      });
    }

    try {
      commit(types.SET_LIABILITY_EVENTS_LOADING, true);

      const queryOptions = {
        first: limit,
        offset: (page - 1) * limit,
        filter: {
          startsAt: {
            greaterThanOrEqualTo: startDate || computeStartsAt(),
          },
        },
        condition: {},
        marketsFilter: {},
        globalMarketsFilter: {},
        lineMarketsFilter: {},
        globalLineMarketsFilter: {},
      };

      if (sport) {
        queryOptions.filter.sportId = {
          in: [sport],
        };
        queryOptions.lineMarketsFilter.marketCode = {
          in: lineMarketCodes,
        };
        queryOptions.globalLineMarketsFilter.marketCode = {
          in: lineMarketCodes,
        };
        queryOptions.marketsFilter.marketCode = {
          in: marketCodes,
        };
        queryOptions.globalMarketsFilter.marketCode = {
          in: marketCodes,
        };
      }

      if (endDate) {
        queryOptions.filter.startsAt.lessThanOrEqualTo = endDate;
      }

      if (search) {
        queryOptions.filter = {
          ...queryOptions.filter,
          eventName: {
            includesInsensitive: search,
          },
        };
      }

      if (competition?.length) {
        queryOptions.filter.competitionId = {
          in: competition,
        };
      }

      if (state?.length) {
        queryOptions.filter.matchState = {
          in: state,
        };
      }

      const response = await api.useAllLiabilityEventsQuery(queryOptions);
      commit(types.UPDATE_LIABILITY_EVENTS, response.data?.allEvents);
      const eventIds = map(response.data?.allEvents?.events, (event) => event.eventId);
      const { isSuperAdmin, SUPER_ADMIN_CLIENT, client } = getSuperAdminData();
      const allOperatorsIds = map(rootGetters.operatorsList, (operator) => operator.operatorId);
      const subscribeToGlobal = isSuperAdmin && client === SUPER_ADMIN_CLIENT;
      socket.subscribeToEventListLiabilities({
        eventIds,
        subscribeToGlobal,
        operatorIds: subscribeToGlobal ? allOperatorsIds : [client],
      });
    } catch (error) {
      console.error('Querying events failed', error);
      commit(types.UPDATE_LIABILITY_EVENTS, []);
    } finally {
      commit(types.SET_LIABILITY_EVENTS_LOADING, false);
    }
  },
  async loadLiabilityFiltersMeta({ commit, dispatch }, data) {
    const startDate = data.from ? startOfDay(parse(data.from, 'yyyy-MM-dd', new Date())) : '';
    if (data.competitionsOnly) {
      const competitions = await api.getCompetitionsWithEvents({
        sportId: data.sport,
        startDate: startDate || computeStartsAt(),
      });
      commit(types.UPDATE_LIABILITY_FILTERS_META, { competitions });
      dispatch('loadLiabilityEvents', data);
      return;
    }
    const response = await api.fetchEligibleSports();
    const selectedSportId = data.sport || response.data.sports.nodes[0]?.sportId;
    const competitions = await api.getCompetitionsWithEvents({
      sportId: selectedSportId,
      startDate: startDate || computeStartsAt(),
    });
    commit(types.UPDATE_LIABILITY_FILTERS_META, { sports: response.data.sports.nodes, competitions });
    dispatch('loadLiabilityEvents', data);
  },
  updateEventListLiabilities({ getters, commit }, data) {
    if (data.messageType === 'SELECTION_LIABILITY' || data.messageType === 'GLOBAL_SELECTION_LIABILITY') return;
    const liabilityEvents = cloneDeep(getters.liabilityEvents);
    const eventToUpdate = find(liabilityEvents, { eventId: data.eventId });
    if (!eventToUpdate) return;
    const mergedEvent = updateLiability(eventToUpdate, data);
    if (mergedEvent.update) {
      const eventIndex = findIndex(liabilityEvents, { eventId: eventToUpdate.eventId });
      liabilityEvents[eventIndex] = mergedEvent.event;
      commit(types.UPDATE_LIABILITY_EVENTS, { events: liabilityEvents, totalCount: getters.liabilityEventsRowCount });
    }
  },
  updateLiabilityEventInfo({ getters, commit }, data) {
    if (data.messageType === 'event-primary-feeds-updated') return;
    const { eventId, matchStatus: matchState } = data ?? {};

    if (!getters.liabilityEvents?.length) return;

    const events = cloneDeep(getters.liabilityEvents);
    const foundEvent = find(events, { eventId });
    const foundEventIndex = find(events, { eventId });
    if (!foundEvent) return;
    foundEvent.matchState = matchState;
    events[foundEventIndex] = foundEvent;
    commit(types.UPDATE_LIABILITY_EVENTS, { events, totalCount: getters.liabilityEventsRowCount });

    const liabilityEvent = cloneDeep(getters.liabilityEvent);
    if (!liabilityEvent || eventId !== liabilityEvent?.eventId) return;
    liabilityEvent.matchState = matchState;
    commit(types.UPDATE_LIABILITY_EVENT, liabilityEvent);
  },
  reloadLiabilities({ getters, rootGetters }) {
    if (getters.liabilityEvents?.length) {
      const { isSuperAdmin, SUPER_ADMIN_CLIENT, client } = getSuperAdminData();
      const allOperatorsIds = map(rootGetters.operatorsList, (operator) => operator.operatorId);
      const subscribeToGlobal = isSuperAdmin && client === SUPER_ADMIN_CLIENT;
      socket.subscribeToEventListLiabilities({
        eventIds: map(getters.liabilityEvents, (e) => e.eventId),
        subscribeToGlobal,
        operatorIds: subscribeToGlobal ? allOperatorsIds : [client],
      });
    }
  },
  clearLiabilityEvents({ getters, commit, rootGetters }) {
    if (getters.liabilityEvents?.length) {
      const { isSuperAdmin, SUPER_ADMIN_CLIENT, client } = getSuperAdminData();
      const allOperatorsIds = map(rootGetters.operatorsList, (operator) => operator.operatorId);
      const subscribeToGlobal = isSuperAdmin && client === SUPER_ADMIN_CLIENT;
      socket.unsubscribeFromEventListLiabilities({
        eventIds: map(getters.liabilityEvents, (e) => e.eventId),
        subscribeToGlobal,
        operatorIds: subscribeToGlobal ? allOperatorsIds : [client],
      });
    }
    commit(types.UPDATE_LIABILITY_EVENTS, { events: [], totalCount: 0 });
    commit(types.UPDATE_LIABILITY_FILTERS_META, { sports: [], competitions: [] });
  },
  setLiabilityEventMarketsAndSelections({ commit, getters }) {
    const event = getters.liabilityEvent;
    const liabilityMarketsObject = reduce(
      event.marketLiabilities.nodes,
      (markets, market) => ({ ...markets, [market.marketId]: market }), {},
    );
    const globalLiabilityMarketsObject = reduce(
      event.globalMarketLiabilities.nodes,
      (markets, market) => (
        {
          ...markets,
          [generateIdentifierByMarketParams(market?.marketCode, market?.marketType?.params)]: market,
        }), {},
    );

    const liabilityMarketSelectionsObject = reduce(
      event.selectionLiabilities.nodes,
      (markets, selection) => {
        const selectionIdentifier = selection.marketId;
        let marketSelections = markets[selectionIdentifier];
        if (marketSelections) {
          marketSelections.push(selection);
        } else {
          marketSelections = [selection];
        }
        return {
          ...markets,
          [selectionIdentifier]: marketSelections,
        };
      }, {},
    );

    const globalLiabilityMarketSelectionsObject = reduce(
      event.globalSelectionLiabilities.nodes,
      (markets, selection) => {
        const selectionIdentifier = generateIdentifierByMarketParams(selection?.marketType?.marketCode, selection?.marketType?.params);
        let marketSelections = markets[selectionIdentifier];
        if (marketSelections) {
          marketSelections.push(selection);
        } else {
          marketSelections = [selection];
        }
        return {
          ...markets,
          [selectionIdentifier]: marketSelections,
        };
      }, {},
    );
    commit(types.SET_LIABILITY_EVENT_MARKETS, liabilityMarketsObject);
    commit(types.SET_GLOBAL_LIABILITY_EVENT_MARKETS, globalLiabilityMarketsObject);
    commit(types.SET_LIABILITY_EVENT_SELECTIONS_BY_MARKET_ID, liabilityMarketSelectionsObject);
    commit(types.SET_GLOBAL_LIABILITY_EVENT_SELECTIONS_BY_IDENTIFIER, globalLiabilityMarketSelectionsObject);
  },
  async loadLiabilityEvent({ commit, dispatch }, { eventId, isGlobal }) {
    try {
      commit(types.SET_LIABILITY_EVENT_LOADING, true);

      // Set is global event liability operator selected
      commit(types.IS_GLOBAL_EVENT_LIABILITY_OPERATOR_SELECTED, isGlobal);

      const response = await api.useLiabilityEventQuery({ id: eventId });
      const event = response.data?.liabilityEvent;
      commit(types.UPDATE_LIABILITY_EVENT, event);
      const { marketDisplayConfigurations } = await api.getMarketDisplayConfigurations({ sportId: event?.sportId });
      const liabilityEventMarketsDisplayConfiguration = marketDisplayConfigurations.reduce((markets, market) => ({
        ...markets,
        [`${market.marketCode}_${market.selections}`]: market,
      }), {});
      commit(types.SET_LIABILITY_DISPLAY_MARKETS_CONFIGURATION, liabilityEventMarketsDisplayConfiguration);

      dispatch('setLiabilityEventMarketsAndSelections');
      try {
        const queryOptions = {
          filter: {
            sportId: {
              in: event.sportId,
            },
          },
        };
        const marketGroupsResponse = await api.getAllMarketGroups(queryOptions);
        commit(types.SET_LIABILITY_MARKET_GROUPS, marketGroupsResponse?.marketGroups?.nodes || []);
      } catch {
        commit(types.SET_LIABILITY_MARKET_GROUPS, []);
      }
    } catch (error) {
      console.error('Querying events failed', error);
      commit(types.UPDATE_LIABILITY_EVENT, null);
      commit(types.SET_LIABILITY_DISPLAY_MARKETS_CONFIGURATION, {});
      commit(types.SET_LIABILITY_EVENT_MARKETS, {});
      commit(types.SET_LIABILITY_EVENT_SELECTIONS_BY_MARKET_ID, {});
      commit(types.IS_GLOBAL_EVENT_LIABILITY_OPERATOR_SELECTED, false);
    } finally {
      commit(types.SET_LIABILITY_EVENT_LOADING, false);
    }
  },
  clearLiabilityEvent({ commit }) {
    commit(types.UPDATE_LIABILITY_EVENT, null);
    commit(types.SET_LIABILITY_DISPLAY_MARKETS_CONFIGURATION, {});
    commit(types.SET_LIABILITY_EVENT_MARKETS, {});
    commit(types.SET_LIABILITY_EVENT_SELECTIONS_BY_MARKET_ID, {});
    commit(types.IS_GLOBAL_EVENT_LIABILITY_OPERATOR_SELECTED, false);
  },
  updateEventLiabilities({ state, getters, commit }, data) {
    const liabilityEvent = cloneDeep(getters.liabilityEvent);
    if (!liabilityEvent || liabilityEvent?.eventId !== data.eventId) return;
    const { messageType } = data;

    if (messageType === 'AGGREGATED_MARKET_LIABILITY' || messageType === 'AGGREGATED_GLOBAL_MARKET_LIABILITY') return;

    const marketLiabilitySocketUpdate = messageType === 'MARKET_LIABILITY';
    const globalMarketLiabilitySocketUpdate = messageType === 'GLOBAL_MARKET_LIABILITY';

    if (marketLiabilitySocketUpdate || globalMarketLiabilitySocketUpdate) {
      const marketIdentifier = globalMarketLiabilitySocketUpdate
        ? generateIdentifierByMarketParams(data.marketType?.marketCode, data.marketType?.params)
        : data.marketId;
      const liabilityMarkets = cloneDeep(
        globalMarketLiabilitySocketUpdate
          ? getters.globalLiabilityEventMarkets
          : getters.liabilityEventMarkets,
      );

      liabilityMarkets[marketIdentifier] = {
        marketCode: liabilityMarkets[marketIdentifier]?.marketCode || data.marketType?.marketCode,
        ...data,
      };
      commit(
        globalMarketLiabilitySocketUpdate
          ? types.SET_GLOBAL_LIABILITY_EVENT_MARKETS
          : types.SET_LIABILITY_EVENT_MARKETS, liabilityMarkets,
      );
    }

    const selectionLiabilitySocketUpdate = messageType === 'SELECTION_LIABILITY';
    const globalSelectionLiabilitySocketUpdate = messageType === 'GLOBAL_SELECTION_LIABILITY';

    if (selectionLiabilitySocketUpdate || globalSelectionLiabilitySocketUpdate) {
      const marketSelectionIdentifier = globalSelectionLiabilitySocketUpdate
        ? generateIdentifierByMarketParams(data.marketType?.marketCode, data.marketType?.params)
        : data.marketId;

      let marketSelections = cloneDeep(
        globalSelectionLiabilitySocketUpdate
          ? getters.getGlobalLiabilityMarketSelectionsByIdentifier(marketSelectionIdentifier)
          : getters.getLiabilityMarketSelectionsById(marketSelectionIdentifier),
      );
      const foundSelectionIndex = findIndex(marketSelections, (selection) => {
        if (globalSelectionLiabilitySocketUpdate) {
          return isEqual(selection.marketType.params, data.marketType.params);
        }
        return selection.selectionId === data.selectionId;
      });
      if (foundSelectionIndex < 0) {
        if (!marketSelections) {
          marketSelections = [data];
        } else {
          marketSelections.push(data);
        }
      } else {
        marketSelections[foundSelectionIndex] = data;
      }
      const allMarketsSelections = cloneDeep(
        globalSelectionLiabilitySocketUpdate
          ? state.globalLiabilityMarketSelectionsObject
          : state.liabilityMarketSelectionsObject,
      );
      allMarketsSelections[marketSelectionIdentifier] = marketSelections;
      commit(
        globalSelectionLiabilitySocketUpdate
          ? types.SET_GLOBAL_LIABILITY_EVENT_SELECTIONS_BY_IDENTIFIER
          : types.SET_LIABILITY_EVENT_SELECTIONS_BY_MARKET_ID, allMarketsSelections,
      );
    }
    const eventLiabilitySocketUpdate = messageType === 'EVENT_LIABILITY';
    const globalEventLiabilitySocketUpdate = messageType === 'GLOBAL_EVENT_LIABILITY';
    if (eventLiabilitySocketUpdate || globalEventLiabilitySocketUpdate) {
      if (globalEventLiabilitySocketUpdate) {
        liabilityEvent.globalEventLiabilities.nodes[0] = {
          betsCount: data.betsCount,
          pnl: data.pnl,
          volume: data.volume,
          operatorId: data.operatorId,
        };
      } else {
        const liabilityEventToUpdate = find(liabilityEvent.eventLiabilities.nodes, { operatorId: data.operatorId });
        liabilityEventToUpdate.betsCount = data.betsCount;
        liabilityEventToUpdate.pnl = data.pnl;
        liabilityEventToUpdate.volume = data.volume;
        liabilityEventToUpdate.operatorId = data.operatorId;
      }
      commit(types.UPDATE_LIABILITY_EVENT, liabilityEvent);
    }
  },

  async initAllCustomers({ getters, commit, dispatch }, options) {
    try {
      if (getters.allCustomersInitialized) {
        await dispatch('loadAllCustomers', options);
        return;
      }
      commit(types.SET_ALL_CUSTOMERS_IS_INITIALIZING, true);
      commit(types.SET_ALL_CUSTOMERS_COLUMNS, getCustomerProfilingListColumns());
      const variables = bulkMapCustomerListFiltersToGraphQl(getters.allCustomersFilters);
      const response = await api.useAllCustomersTotalCountQuery(variables);
      commit(types.SET_ALL_CUSTOMERS_TOTAL_COUNT, response.data.allCustomers?.totalCount || 0);
      await dispatch('loadAllCustomers', options);
      commit(types.SET_ALL_CUSTOMERS_INITIALIZED, true);
    } catch (error) {
      console.error(error);
    } finally {
      commit(types.SET_ALL_CUSTOMERS_IS_INITIALIZING, false);
    }
  },
  loadAllCustomers({ getters, commit, dispatch }, options) {
    const {
      filters = [],
      page = 1,
      limit = 50,
      reset = false,
    } = options || {};

    commit(types.SET_ALL_CUSTOMERS_IS_LOADING, true);
    commit(types.SET_ALL_CUSTOMERS_FILTERS, filters);
    const parsedLimit = parseLimit(limit);
    commit(types.SET_ALL_CUSTOMERS_LIMIT, parsedLimit);
    const parsedPage = parsePage(page, {
      totalCount: getters.allCustomersTotalCount,
      limit: parsedLimit,
    });
    commit(types.SET_ALL_CUSTOMERS_PAGE, parsedPage);

    if (reset) {
      commit(types.SET_ALL_CUSTOMERS_TOTAL_COUNT, 0);
      commit(types.SET_ALL_CUSTOMERS_PAGE, 1);
    }

    return dispatch('reloadAllCustomers', options);
  },
  async reloadAllCustomers({ commit, getters }) {
    try {
      commit(types.SET_ALL_CUSTOMERS_IS_LOADING, true);
      commit(types.SET_ALL_CUSTOMERS_DATA, []);
      const appliedSort = getters.customerListAppliedSort;

      const variables = assign(
        {},
        {
          first: getters.allCustomersLimit,
          offset: (getters.allCustomersPage - 1) * getters.allCustomersLimit,
        },
        bulkMapCustomerListFiltersToGraphQl(getters.allCustomersFilters),
      );

      if (appliedSort) {
        const sortFieldParts = filter(split(appliedSort.field, /([A-Z]?[^A-Z]*)/g), (value) => value);
        const sortField = toUpper(join(sortFieldParts, '_'));
        let orderField = `${sortField}_${appliedSort.order}`;

        switch (sortField) {
        case 'TOTAL_BETS_COUNT':
          orderField = `ACCOUNT_KPI_BY_PLAYER_ACCOUNT_ID_AND_OPERATOR_ID__BETS_COUNT_${appliedSort.order}`;
          break;
        case 'VOLUME':
          orderField = `ACCOUNT_KPI_BY_PLAYER_ACCOUNT_ID_AND_OPERATOR_ID__${sortField}_${appliedSort.order}`;
          break;
        case 'BET_SIZE':
          orderField = `ACCOUNT_KPI_BY_PLAYER_ACCOUNT_ID_AND_OPERATOR_ID__AVERAGE_STAKE_${appliedSort.order}`;
          break;
        case 'HOLD':
          orderField = `ACCOUNT_KPI_BY_PLAYER_ACCOUNT_ID_AND_OPERATOR_ID__HOLD_PERCENTAGE_${appliedSort.order}`;
          break;
        case 'EDGE':
          orderField = `ACCOUNT_KPI_BY_PLAYER_ACCOUNT_ID_AND_OPERATOR_ID__EDGE_PERCENTAGE_${appliedSort.order}`;
          break;
        case 'FIRST_PLACED_BET':
          orderField = `${sortField}_${appliedSort.order}`;
          break;
        case 'LAST_NOTE':
          orderField = `LAST_NOTE_AT_${appliedSort.order}`;
          break;
        default:
          orderField = 'FIRST_PLACED_BET_DESC';
          break;
        }

        variables.orderBy = [orderField];
      }

      const allCustomersResponse = await api.useAllCustomersQuery(variables);
      commit(types.SET_ALL_CUSTOMERS_TOTAL_COUNT, allCustomersResponse.data?.allCustomers?.totalCount || 0);
      commit(types.SET_ALL_CUSTOMERS_DATA, map(allCustomersResponse.data?.allCustomers?.nodes || [], flattenCustomerObject));
    } catch (error) {
      console.error(error);
    } finally {
      commit(types.SET_ALL_CUSTOMERS_IS_INITIALIZING, false);
      commit(types.SET_ALL_CUSTOMERS_IS_LOADING, false);
    }
  },
  setAllCustomersFilters({ commit }, filters) {
    commit(types.SET_ALL_CUSTOMERS_FILTERS, filters);
  },
  setAllCustomersSidebar({ commit }, sidebar) {
    commit(types.SET_ALL_CUSTOMERS_SIDEBAR, sidebar);
  },
  async applyCustomerProfileFlag({ getters, commit }, { accountId, isFlagged }) {
    const customerProfiles = getters.allCustomersData || [];
    const customerProfileIndex = findIndex(customerProfiles, { id: accountId });
    if (customerProfileIndex === -1) return;
    const { operatorId } = customerProfiles[customerProfileIndex];

    try {
      const updatedCustomerProfiles = map(customerProfiles, (customerProfileToChange) => {
        if (accountId !== customerProfileToChange.id) return customerProfileToChange;
        return {
          ...cloneDeep(customerProfileToChange),
          isFlagged,
        };
      });
      commit(types.SET_ALL_CUSTOMERS_DATA, updatedCustomerProfiles);
      await api.setCustomerProfileFlag({
        accountId,
        operatorId,
        isFlagged,
      });
    } catch (error) {
      console.error(error);
      commit(types.SET_ALL_CUSTOMERS_DATA, customerProfiles);
    }
  },
  async applyCustomerProfileStrength({ getters, commit }, { accountId, strength }) {
    const customerProfiles = getters.allCustomersData || [];
    const customerProfileIndex = findIndex(customerProfiles, { id: accountId });
    if (customerProfileIndex === -1) return;
    const { operatorId } = customerProfiles[customerProfileIndex];

    try {
      const updatedCustomerProfiles = map(customerProfiles, (customerProfileToChange) => {
        if (accountId !== customerProfileToChange.id) return customerProfileToChange;
        return {
          ...cloneDeep(customerProfileToChange),
          strength,
        };
      });
      commit(types.SET_ALL_CUSTOMERS_DATA, updatedCustomerProfiles);
      await api.setCustomerProfileStrength({
        accountId,
        operatorId,
        strength,
      });
    } catch (error) {
      console.error(error);
      commit(types.SET_ALL_CUSTOMERS_DATA, customerProfiles);
    }
  },
  setCustomerProfilesColumn({ getters, commit }, { key, value }) {
    const updatedColumns = map(getters.allCustomersColumns, (column) => {
      if (key !== column.key) return column;
      return value;
    });
    commit(types.SET_ALL_CUSTOMERS_COLUMNS, updatedColumns);
  },
  reorderCustomerProfilesColumns({ getters, commit }, { from, to }) {
    const updatedColumns = cloneDeep(getters.allCustomersColumns);
    const column = updatedColumns[from];
    updatedColumns.splice(from, 1);
    updatedColumns.splice(to, 0, column);
    commit(types.SET_ALL_CUSTOMERS_COLUMNS, updatedColumns);
  },
  clearAllCustomers({ commit }) {
    commit(types.SET_ALL_CUSTOMERS_IS_INITIALIZING, false);
    commit(types.SET_ALL_CUSTOMERS_IS_LOADING, false);
    commit(types.SET_ALL_CUSTOMERS_INITIALIZED, false);
    commit(types.SET_ALL_CUSTOMERS_FILTERS, []);
    commit(types.SET_ALL_CUSTOMERS_PAGE, 1);
    commit(types.SET_ALL_CUSTOMERS_LIMIT, 50);
    commit(types.SET_ALL_CUSTOMERS_COLUMNS, []);
    commit(types.SET_ALL_CUSTOMERS_TOTAL_COUNT, 0);
    commit(types.SET_ALL_CUSTOMERS_DATA, []);
    commit(types.SET_ALL_CUSTOMERS_SIDEBAR, '');
  },

  async setCustomerInitializing({ commit }) {
    commit(types.SET_CUSTOMER_IS_INITIALIZING, true);
  },
  async initCustomer({ commit, dispatch }, options) {
    try {
      commit(types.SET_CUSTOMER_IS_INITIALIZING, true);

      const marketDisplayConfigurationsResponse = await api.loadAllSportsMarketDisplayConfigurations();
      const marketDisplayConfigurations = marketDisplayConfigurationsResponse?.data?.allMarketDisplayConfigurations?.nodes || [];
      const marketsDisplayConfigurationObj = marketDisplayConfigurations.reduce((markets, market) => ({
        ...markets,
        [`${market.sportId}_${market.marketCode}_${market.selections}`]: market,
      }), {});
      commit(types.SET_ALL_SPORTS_DISPLAY_MARKETS_CONFIGURATION, marketsDisplayConfigurationObj);

      const { id = '', operator = '' } = options || {};
      commit(types.SET_CUSTOMER_KEY, { id, operator });
      commit(types.SET_CUSTOMER_BETS_COLUMNS, getCustomerProfilingDetailsColumns());
      await dispatch('loadCustomer', options);
    } catch (error) {
      console.error(error);
    } finally {
      commit(types.SET_CUSTOMER_IS_INITIALIZING, false);
    }
  },
  loadCustomer({ commit, dispatch }, options) {
    commit(types.SET_CUSTOMER_BETS_FILTERS, options?.filters || []);
    return dispatch('reloadCustomer');
  },
  loadMoreCustomer({ getters, commit, dispatch }) {
    commit(types.SET_CUSTOMER_BETS_PAGE, getters.customerBetsPage + 1);
    return dispatch('reloadCustomer', { reload: true });
  },
  async reloadCustomer({ rootGetters, getters, commit }, options) {
    try {
      commit(types.SET_CUSTOMER_IS_LOADING, true);
      commit(types.SET_CUSTOMER_BETS_IS_LOADING, true);

      const isReload = options?.reload || false;

      if (!isReload) {
        commit(types.SET_CUSTOMER_BETS_PAGE, 1);
        commit(types.SET_CUSTOMER_BETS_LIMIT, 50);
        commit(types.SET_CUSTOMER_BETS_TOTAL_COUNT, 0);
        commit(types.SET_CUSTOMER_BETS_DATA, []);
      }

      if (options?.recalculateKpis) {
        await api.recalculateCustomerKpis({
          accountId: getters.customerKey?.id || '',
          operatorId: getters.customerKey?.operator || '',
        });
      }

      const variables = assign(
        {},
        {
          id: getters.customerKey?.id || '',
          operator: getters.customerKey?.operator || '',
          first: getters.customerBetsLimit,
          offset: (getters.customerBetsPage - 1) * getters.customerBetsLimit,
        },
        bulkMapCustomerDetailsFiltersToGraphQl(getters.customerBetsFilters, {
          oddsFormat: rootGetters.selectedOddFormat?.id || '',
        }),
      );
      const customerResponse = await api.useCustomerQuery(variables);
      const allCustomerData = customerResponse.data.customer;
      if (!allCustomerData) return;
      const { bets, ...customer } = allCustomerData;
      commit(types.SET_CUSTOMER_DATA, flattenCustomerObject(customer));
      commit(types.SET_CUSTOMER_BETS_TOTAL_COUNT, bets?.totalCount || 0);
      socket.subscribeToCustomerProfilingBets({ playerIds: [getters.customerKey?.id], operatorIds: [getters.customerKey?.operator] });

      if (isReload) {
        commit(types.SET_CUSTOMER_BETS_DATA, [
          ...getters.customerBetsData,
          ...formatBetsData(bets?.nodes || []),
        ]);
      } else {
        commit(types.SET_CUSTOMER_BETS_DATA, formatBetsData(bets?.nodes || []));
      }
    } catch (error) {
      console.error(error);
    } finally {
      commit(types.SET_CUSTOMER_IS_LOADING, false);
      commit(types.SET_CUSTOMER_BETS_IS_LOADING, false);
      commit(types.SET_CUSTOMER_IS_INITIALIZING, false);
    }
  },
  setCustomerSidebar({ commit }, sidebar) {
    commit(types.SET_CUSTOMER_SIDEBAR, sidebar);
  },
  setCustomerBetsFilters({ commit }, filters) {
    commit(types.SET_CUSTOMER_BETS_FILTERS, filters);
  },
  toggleCustomerProfilingDetailsParlayBet({ getters, commit }, betId) {
    let betList = cloneDeep(getters.customerBetsData);
    const singleBetIndex = findIndex(betList, (bet) => bet.betId === betId);
    const singleBet = betList[singleBetIndex];
    singleBet.toggled = !singleBet.toggled;
    if (singleBet.toggled) {
      betList = [
        ...slice(betList, 0, singleBetIndex + 1),
        ...singleBet.betParts,
        ...slice(betList, singleBetIndex + 1),
      ];
    } else {
      betList = filter(betList, (bet, index) => (index < singleBetIndex + 1 || index > singleBetIndex + singleBet.betParts.length));
    }
    commit(types.SET_CUSTOMER_BETS_DATA, betList);
  },
  async toggleCustomerProfileDetailsIsFlagged({ getters, commit }) {
    const oldIsFlagged = getters.customerData.isFlagged;
    try {
      commit(types.SET_CUSTOMER_DATA, {
        ...getters.customerData,
        isFlagged: !oldIsFlagged,
      });
      await api.setCustomerProfileFlag({
        accountId: getters.customerData.id,
        operatorId: getters.customerData.operatorId,
        isFlagged: !oldIsFlagged,
      });
    } catch (error) {
      console.error(error);
      commit(types.SET_CUSTOMER_DATA, {
        ...getters.customerData,
        isFlagged: oldIsFlagged,
      });
    }
  },
  async setCustomerProfileDetailsStrength({ getters, commit }, strength) {
    const oldStrength = getters.customerData.strength;
    try {
      commit(types.SET_CUSTOMER_DATA, {
        ...getters.customerData,
        strength,
      });
      await api.setCustomerProfileStrength({
        accountId: getters.customerData.id,
        operatorId: getters.customerData.operatorId,
        strength,
      });
    } catch (error) {
      console.error(error);
      commit(types.SET_CUSTOMER_DATA, {
        ...getters.customerData,
        strength: oldStrength,
      });
    }
  },
  openCustomerEditNicknameModal({ commit }) {
    commit(types.SET_CUSTOMER_EDIT_NICKNAME, true);
  },
  async applyCustomerNickname({ getters, commit }, nickname) {
    const oldNickname = getters.customerData.nickname;
    try {
      commit(types.SET_CUSTOMER_DATA, {
        ...getters.customerData,
        nickname,
      });
      await api.setCustomerProfileNickname({
        accountId: getters.customerData.id,
        operatorId: getters.customerData.operatorId,
        nickname,
      });
    } catch (error) {
      console.error(error);
      commit(types.SET_CUSTOMER_PROFILING_DETAILS_CUSTOMER, {
        ...getters.customerProfilingDetailsCustomer,
        nickname: oldNickname,
      });
    }
  },
  closeCustomerEditNicknameModal({ commit }) {
    commit(types.SET_CUSTOMER_EDIT_NICKNAME, false);
  },
  applyCustomerBetFlag({ getters, commit }, { betId, isFlagged }) {
    const oldBetList = cloneDeep(getters.customerBetsData);
    const newBetList = cloneDeep(getters.customerBetsData);
    const singleBet = find(newBetList, (bet) => bet.betId === betId);
    singleBet.isFlagged = isFlagged;
    commit(types.SET_CUSTOMER_BETS_DATA, newBetList);

    const action = isFlagged ? api.flag : api.unflag;
    action(betId)
      .catch((error) => {
        console.error(error);
        commit(types.SET_CUSTOMER_BETS_DATA, oldBetList);
      });
  },
  initCustomerNotes({ commit, dispatch, getters }) {
    commit(types.SET_CUSTOMER_NOTES_IS_INITIALIZING, true);
    socket.subscribeToCustomerProfilingEvents({ playerIds: [getters.customerKey?.id], operatorIds: [getters.customerKey?.operator] });
    return dispatch('loadCustomerNotes');
  },
  async setCustomerNotesLoading({ commit }, isLoading) {
    commit(types.SET_CUSTOMER_NOTES_IS_LOADING, isLoading);
  },
  async setCustomerNotesData({ commit }, newData) {
    commit(types.SET_CUSTOMER_NOTES_DATA, newData);
  },
  async loadCustomerNotes({ getters, commit, dispatch }, options) {
    const oldTotalCount = getters.customerNotesTotalCount;
    const oldNotesData = cloneDeep(getters.customerNotesData);
    try {
      commit(types.SET_CUSTOMER_NOTES_IS_LOADING, true);
      commit(types.SET_CUSTOMER_NOTES_ORDER_BY, options?.orderBy || 'CREATED_AT_DESC');
      commit(types.SET_CUSTOMER_NOTES_FIRST, isNil(options?.first) ? 5 : options.first);
      const response = await api.useCustomerCommentsQuery({
        customerId: getters.customerKey?.id || '',
        orderBy: getters.customerNotesOrderBy,
        first: getters.customerNotesFirst,
      });
      commit(types.SET_CUSTOMER_NOTES_TOTAL_COUNT, response.data?.comments?.totalCount || 0);
      commit(types.SET_CUSTOMER_NOTES_DATA, response.data?.comments?.nodes || []);
    } catch (error) {
      console.error(error);
      dispatch('addNotification', {
        message: 'Could not load comments. Please try again later.',
        type: 'error',
        duration: 5000,
      });
      commit(types.SET_CUSTOMER_NOTES_TOTAL_COUNT, oldTotalCount);
      commit(types.SET_CUSTOMER_NOTES_DATA, oldNotesData);
    } finally {
      commit(types.SET_CUSTOMER_NOTES_IS_INITIALIZING, false);
      commit(types.SET_CUSTOMER_NOTES_IS_LOADING, false);
    }
  },
  reloadCustomerNotes({ getters, dispatch }) {
    return dispatch('loadCustomerNotes', {
      orderBy: getters.customerNotesOrderBy,
      first: getters.customerNotesFirst,
    });
  },
  clearCustomerNotes({ commit, getters }) {
    commit(types.SET_CUSTOMER_NOTES_IS_INITIALIZING, false);
    commit(types.SET_CUSTOMER_NOTES_IS_LOADING, false);
    commit(types.SET_CUSTOMER_NOTES_ORDER_BY, 'CREATED_AT_DESC');
    commit(types.SET_CUSTOMER_NOTES_FIRST, 5);
    commit(types.SET_CUSTOMER_NOTES_TOTAL_COUNT, 0);
    commit(types.SET_CUSTOMER_NOTES_DATA, []);
    socket.unsubscribeFromCustomerProfilingEvents({ playerIds: [getters.customerKey?.id], operatorIds: [getters.customerKey?.operator] });
  },
  clearCustomer({ commit, dispatch, getters }) {
    socket.unsubscribeFromCustomerProfilingBets({ playerIds: [getters.customerKey?.id], operatorIds: [getters.customerKey?.operator] });
    commit(types.SET_CUSTOMER_IS_INITIALIZING, false);
    commit(types.SET_CUSTOMER_IS_LOADING, false);
    commit(types.SET_CUSTOMER_KEY, null);
    commit(types.SET_CUSTOMER_DATA, null);
    commit(types.SET_CUSTOMER_SIDEBAR, '');
    commit(types.SET_CUSTOMER_EDIT_NICKNAME, false);
    commit(types.SET_CUSTOMER_BETS_IS_LOADING, false);
    commit(types.SET_CUSTOMER_BETS_FILTERS, []);
    commit(types.SET_CUSTOMER_BETS_PAGE, 1);
    commit(types.SET_CUSTOMER_BETS_LIMIT, 50);
    commit(types.SET_CUSTOMER_BETS_COLUMNS, []);
    commit(types.SET_CUSTOMER_BETS_TOTAL_COUNT, 0);
    commit(types.SET_CUSTOMER_BETS_DATA, []);
    dispatch('clearCustomerNotes');
  },
  processEventDisabled({ getters, commit }, eventId) {
    if (!getters.liabilityEvents?.length) return;

    if (getters.liabilityEvent && getters.liabilityEvent.eventId === eventId) {
      const event = cloneDeep(getters.liabilityEvent);
      event.inLimbo = true;
      commit(types.UPDATE_LIABILITY_EVENT, event);
    }

    const events = cloneDeep(getters.liabilityEvents);
    const foundEvent = find(events, { eventId });
    const foundEventIndex = find(events, { eventId });
    if (!foundEvent) return;

    foundEvent.inLimbo = true;
    events[foundEventIndex] = foundEvent;
    commit(types.UPDATE_LIABILITY_EVENTS, { events, totalCount: getters.liabilityEventsRowCount });
  },
  async updateCustomerNotes({ dispatch }) {
    dispatch('reloadCustomerNotes');
  },
};
